import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import api from "../../../../../services/api";
import { formatDate } from "@/component/formatter";
import Loading from "../../../components/loading/loading";

function Row({ row }) {

  return (
    <>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "180px",
            whiteSpace: "nowrap",
          }}
        >
          {row?.talent ? row?.talent?.first_name + " " + row?.talent?.last_name : 'Deleted Talent'}
        </TableCell>
        <TableCell className="custom-cell">
          {" "}
          <span style={{ backgroundColor: row?.talent?.full_time_decision === 'full-time' ? '#B7E6CA' : '#FFE299', padding: '3px 15px', borderRadius: '15px' }}>{row?.talent?.full_time_decision === 'full-time' ? 'Talent' : 'Freelancer'}</span>{" "}
        </TableCell>
        <TableCell className="custom-cell">
          {formatDate(row?.usedAt)}
        </TableCell>
        <TableCell className="custom-cell">
          <span style={{ backgroundColor: '#B7E6CA', padding: '3px 15px', borderRadius: '10px' }}>{'Completed'}</span>{" "}
        </TableCell>
      </TableRow>
    </>
  );
}

const PromotionDetails = ({ user }) => {
  const [promo, setPromo] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    api.getPromoUsage(user?._id).then(res => setPromo(res.data)).catch(console.error).finally(() => setIsLoading(false))
  }, [user?._id])


  return (
    <div className="user_feedback my-5">
      <div className="user_feedback_header">
        <div className="d-flex flex-column gap-3" style={{ maxWidth: '85%' }}>
          <h6>Description</h6>
          <p>{user.description}.</p>
        </div>
      </div>
      <TableContainer sx={{ maxWidth: '80%', marginTop: '30px' }}>
        <Table aria-label="collapsible table" sx={{ border: "none" }}>
          <TableHead>
            <TableRow className="custom-row">
              <TableCell>Users</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Date Used</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : promo.map((row, index) => {
              return <Row row={row} key={index} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PromotionDetails;

import Modal from "@mui/material/Modal";
import Modalheader from "../Modalheader";
import "../modal.css";
import Box from "@mui/material/Box";
import InputField from "../../inputField/InputField";
import Button from "@/component/buttons/Button";
import { Save } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 530,

  width: "100%",
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const UpdatePromoCoupon = ({
  open,
  details,
  handleClose,
  send,
  handleChange,
  newCode
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"SET PROMO CODE"} handleClose={handleClose} />
          <div className="modal_body p-3">
            <div>
              <article className="d-flex gap-3 mt-3 align-items-end">
                <div className="w-75">
                  <InputField
                    name={"code"}
                    value={details.code}
                    onChange={handleChange}
                    placeholder=""
                    title="Code"
                    variant="outlined"
                    className={"modal_input,"}
                    style={{ outline: "none" }}
                  />
                </div>
                <div>
                  <Button
                    text="Change code"
                    primaryNull={true}
                    small={true}
                    onClick={newCode}
                  />
                </div>
              </article>
            </div>
            <div>
              <article className="d-md-flex gap-3 align-items-center mt-3">
                <div className="w-md-full w-50">
                  <InputField
                    name={"discount"}
                    value={details.discount}
                    onChange={handleChange}
                    placeholder="Enter percentage here..."
                    title="Discount (%)"
                    variant="outlined"
                    className={"modal_input"}
                    style={{ outline: "none" }}
                  />
                </div>
                <div className="w-md-full w-50">
                  <InputField
                    name={"userType"}
                    value={details.userType}
                    onChange={handleChange}
                    placeholder="Select all that apply"
                    title="User type"
                    variant="outlined"
                    className={"modal_input"}
                    style={{ outline: "none" }}
                    isSelect
                    selectOptions={["talent", "freelancer", "all"]}
                  />
                </div>
              </article>
            </div>
            <div className="mt-4">
              <InputField
                name={"description"}
                value={details.description}
                onChange={handleChange}
                placeholder="Joe"
                title="Description"
                variant="outlined"
                className={"modal_input,"}
                style={{ outline: "none" }}
                multiline
                rows={7}
              />
            </div>

            <div className="d-flex gap-3 align-items-center mt-3">
              <Button
                text="Cancel"
                primaryNull={true}
                small={true}
                onClick={() => handleClose()}
              />
              <Button
                text="Save"
                primaryNull={false}
                small={true}
                onClick={send}
                icon={<Save />}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdatePromoCoupon;

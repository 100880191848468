import React, { useEffect } from "react";
import { useState } from "react";
import "./css/Referral.css";
import Button from "@/component/buttons/Button";
import CollapsibleTable from "./promoTable/PromoTable";
import CsvDownloadButton from "react-json-to-csv";
import api from "../../../../services/api";
import InputField from "../../components/inputField/InputField";
import Loading from "../../components/loading/loading";
import Pagination from "../../components/pagination/Pagination";
import { FormControlLabel, Switch, styled } from "@mui/material";
import UpdatePromoCoupon from "../../components/modal/Promo coupon update/PromoCouponUpdate";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "grey", // Rest state color
    "&.Mui-checked": {
      color: "#CB5600", // Checked state color
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#CB5600", // Checked state track color
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "grey", // Rest state track color
  },
}));

const Promo = () => {
  const [promos, setPromos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    status: "",
    startDate: "",
    endDate: "",
    code: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const [updateCoupon, setUpdateCoupon] = useState(false);

  const [allCouponsClosed, setAllCouponsClosed] = useState(false);

  const handleAllCouponsClose = (event) => {
    setIsLoading(true)
    api.deactivateAllPromo().then(res => { setAllCouponsClosed(!allCouponsClosed); }).catch(console.error).finally(() => setIsLoading(false))
  };

  const [formDetails, setFormDetails] = useState({
    code: "",
    discount: "",
    userType: "",
    description: "",
  });

  const generateRandomCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    for (let i = 0; i < 8; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
  };

  const handleChangeCode = () => {
    const newCode = generateRandomCode();
    setFormDetails(prevDetails => ({
      ...prevDetails,
      code: newCode
    }));
  };

  const handleUpdateCouponChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    const filters = {};
    if (filterOptions.status) {
      filters.status = filterOptions.status;
    }
    if (filterOptions.startDate) {
      filters.startDate = filterOptions.startDate;
    }
    if (filterOptions.endDate) {
      filters.endDate = filterOptions.endDate;
    }
    if (filterOptions.code) {
      filters.code = filterOptions.code;
    }
    const fetchPromos = () => {
      api
        .getPromos({
          ...filters,
          page: currentPage,
          limit: itemsPerPage,
        })
        .then((res) => {
          setPromos(res.data.promoCoupons)
        })
        .catch((res) => console.log(res))
        .finally(() => setIsLoading(false));
    };

    fetchPromos();
  }, [currentPage, filterOptions, itemsPerPage]);

  // pagination
  const totalNumberOfPages = Math.ceil(promos.length / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      code: searchTerm,
    }));
  };

  const createPromoCoupon = () => {
    setIsLoading(true)
    if (formDetails) {
      api.createPromo(formDetails).then(res => { setUpdateCoupon(false) }).catch(console.error).finally(() => setIsLoading(false))
    }
  }

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <div className="top-container">
            <h2 className="name my-2">Promo Coupons</h2>
            <div className="d-flex gap-2 align-items-center">
              <FormControlLabel
                value="Close all coupons"
                control={
                  <CustomSwitch
                    onChange={handleAllCouponsClose}
                    checked={allCouponsClosed}
                  />
                }
                label={<small>Close all coupons</small>}
                labelPlacement="start"
              />
              <Button
                icon={<i class="fa-solid fa-circle-plus"></i>}
                text="Create Coupon"
                primaryNull={true}
                small={true}
                onClick={() => setUpdateCoupon(!updateCoupon)}
              />
            </div>
          </div>
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between ">
                <div className="form d-lg-flex align-items-start gap-4 col-lg-10">
                  <div
                    className={`input-search ${filterType === "" && "col-lg-5"
                      } col-lg-4 d-flex py-2 mt-1`}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for codes"
                      className="input_text"
                      value={filterOptions.code}
                      onChange={handleSearch}
                    />
                  </div>
                  <div
                    className={`input-filter  ${filterType === "" && "col-lg-5"
                      } col-lg-3 d-flex pt-3`}
                  >
                    <select
                      className="input_text"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <option selected value="">
                        Filter by date range
                      </option>
                      <option value="date">date</option>
                    </select>
                  </div>
                  {filterType !== "" && (
                    <div className="col-lg-3 d-flex align-items-end py-2 my-4 my-lg-0">
                      {filterType === "date" && (
                        <div className="row" style={{ marginTop: "-20px" }}>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ width: "80%" }}
                          >
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"Start date"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    startDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"End date"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    endDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="d-flex gap-3 my-3 my-lg-0">
                    <div className="position-relative">
                      <Button
                        text="Sort"
                        primaryNull={true}
                        small={true}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                      {isOpen && (
                        <div
                          className="position-absolute text-white p-2 rounded mt-2"
                          style={{
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            minWidth: "180px",
                            backgroundColor: "#CC5600",
                          }}
                        >
                          <Button
                            text={`By Date`}
                            primaryNull
                            small
                            onClick={""}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <CsvDownloadButton
                        data={[]}
                        filename={`joblisting-${currentPage}`}
                      >
                        <Button
                          text="Export"
                          primaryNull={false}
                          small={true}
                        />
                      </CsvDownloadButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <CollapsibleTable rows={promos} />
                  <Pagination
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalNumberOfPages={totalNumberOfPages}
                  />
                </>
              )}
            </>
          </div>
          <UpdatePromoCoupon
            open={updateCoupon}
            details={formDetails}
            handleChange={handleUpdateCouponChange}
            handleClose={() => setUpdateCoupon(false)}
            send={createPromoCoupon}
            newCode={handleChangeCode}
          />
        </div>
      </div>
    </div>
  );
};

export default Promo;

import Modal from "@mui/material/Modal";
import Modalheader from "../Modalheader";
import "../modal.css";
import Box from "@mui/material/Box";
import InputField from "../../inputField/InputField";
import Button from "@/component/buttons/Button";
import { Save } from "@mui/icons-material";
import { useEffect, useState } from "react";
import api from '@/services/api'
import { IconButton, Radio } from "@mui/material";
import { Search, Cancel } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 530,
  height: 'auto',

  width: '100%',
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const AwardBadge = ({ open, handleClose, send, handleChange }) => {
  const [searchTalent, setSearchTalent] = useState("");
  const [displayTitle, setDisplayTitle] = useState(false);
  const [userType, setUserType] = useState('');

  const [badges, setBadges] = useState([])
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [awardee, setAwardee] = useState({
    talent: '',
    badge: ''
  })
  const [talents, setTalents] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  

  const fetchBadges = () => {
    api.getBadges().then(res => setBadges(res.data.badges)).catch(console.error)
  }

  const fetchMoreBadges = () => {
    setItemsPerPage((prev) => prev + 10)
  }
  const fetchMoreTalents = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    fetchBadges()
  }, [itemsPerPage])

  useEffect(() => {
    const fetchData = () => {
      const filters = {};
      if (searchTalent) {
        filters.talentName = searchTalent;
      }
      api.getTalents({ ...filters, pageNumber: currentPage, pageSize: itemsPerPage })
        .then((res) => {
          setTalents(res.data.talents);
        })
        .catch((err) => console.log(err))
        .finally();
    };
    fetchData()
  }, [currentPage, itemsPerPage, searchTalent])

  const handleUserTypeChange = (e) => {
    const selectedUserType = e.target.value;
    setUserType(selectedUserType);

    // Filter badges based on the selected user type
    const filtered = badges.filter((badge) => badge.userType === selectedUserType || selectedUserType === 'all');
    setFilteredBadges(filtered);
  };

  const handleTalentSearchChange = (event) => {
    setDisplayTitle(true);
    setSearchTalent(event.target.value);
  };

  const filteredTalents = talents.filter((item) =>
    `${item.first_name} ${item.last_name}`
      .toLowerCase()
      .includes(searchTalent.toLowerCase())
  );

  const handleRadioChange = (item) => {
    setAwardee((prev) => ({
      ...prev,
      talent: item._id,
    }));
  };

  const awardBadge = () => {
    api.awardBadge(awardee).then(res => { handleClose() }).catch(console.error)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"AWARD USER"} handleClose={handleClose} />
          <div className="modal_body p-3">
            <div className="d-flex flex-column gap-3">
              <InputField
                name={"userType"}
                value={userType}
                onChange={handleUserTypeChange}
                placeholder="Select all that apply"
                title="User type"
                variant="outlined"
                className={"modal_input"}
                style={{ outline: "none" }}
                isSelect
                selectOptions={["talent", "freelancer", "all"]}
              />
              <article >
                <div className='d-flex flex-column gap-2'>
                  <label htmlFor="">Badge title</label>
                  <select
                    className="input_text"
                    value={awardee.badge}
                    onChange={
                      (e) =>
                        setAwardee((prev) => (
                          {
                            ...prev,
                            badge: e.target.value
                          }))
                    }
                    style={{ border: '1px solid black', padding: '10px 5px', borderRadius: '5px' }}
                  >
                    <option selected value="">
                      Select badge title
                    </option>
                    {
                      filteredBadges.map((badge) => {
                        return (
                          <option key={badge._id} value={badge._id}>{badge.name}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div>
                  <button onClick={fetchMoreBadges} type="button">Load more</button>
                </div>
              </article>

              <div className="d-flex flex-column input_select_con w-100">
                <div
                  className="input_text"
                  style={{
                    marginBottom: "10px",
                    padding: "5px",
                    minWidth: "150px",
                    position: "relative",
                  }}
                >
                  <InputField
                    title={'User name'}
                    placeholder={"Search For Talents"}
                    type={"text"}
                    value={searchTalent}
                    onChange={handleTalentSearchChange}
                    onFocus={() => setDisplayTitle(true)}
                    isIconPresent={true}
                    icon={Search}
                    iconPosition="start"
                  />
                </div>
                {displayTitle && (
                  <div style={{ height: '150px', overflow: 'scroll' }}>
                    <div
                      className="d-flex justify-content-end "

                    >
                      <IconButton
                        onClick={() => setDisplayTitle(false)}
                      >
                        <Cancel
                          sx={{
                            width: "18px",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </div>

                    <ul style={{ listStyleType: "none", }} className="py-1 px-0">
                      {filteredTalents.map((item) => (
                        <li
                          key={item._id}
                          className="d-flex  align-items-center mb-1 gap-3"
                        >
                          <div className="">
                            <Radio
                              type="radio"
                              id={item._id}
                              name="selectedItem"
                              value={item._id}
                              checked={awardee.talent === item._id}
                              onChange={() => handleRadioChange(item)}
                              sx={{
                                color: "#CB5600",

                                "&.Mui-checked": {
                                  color: "#CB5600",
                                },
                                '& .MuiSvgIcon-root': {
                                  fontSize: 18,
                                },
                              }}
                            />
                          </div>
                          <label
                            htmlFor={item._id}
                            className="flex-2 m-0 title_text"
                          >{item.first_name} {item.last_name} {" "} ({item.role})</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <Button
                text="More Talents"
                primaryNull={true}
                small={true}
                onClick={fetchMoreTalents}
              />
            </div>
            <div className="d-flex gap-3 align-items-center mt-3">
              <Button
                text="Cancel"
                primaryNull={true}
                small={true}
                onClick={() => handleClose()}
              />
              <Button
                text="Save"
                primaryNull={false}
                small={true}
                onClick={awardBadge}
                icon={<Save />}
              />

            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default AwardBadge;

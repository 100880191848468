import Modal from "@mui/material/Modal";
import Modalheader from "../Modalheader";
import "../modal.css";
import Box from "@mui/material/Box";
import InputField from "../../inputField/InputField";
import Button from "@/component/buttons/Button";
import { Save } from "@mui/icons-material";
import { useRef } from "react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 530,

  width: '100%',
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const CreateBadge = ({ open, details, handleClose, send, handleChange, imageChange, }) => {

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"ADD BADGE"} handleClose={handleClose} />
          <div className="modal_body p-3">
            <div className="d-flex flex-column gap-2">
              <div>
                <InputField
                  name={'name'}
                  value={details.name}
                  onChange={handleChange}
                  placeholder="Enter badge title"
                  title="Badge title"
                  variant="outlined"
                  className={"modal_input,"}
                  style={{ outline: 'none' }}
                />
              </div>
              <div className="d-flex gap-2 align-items-end">
                <div className='w-100'>
                  <InputField
                    name={"userType"}
                    value={details.userType}
                    onChange={handleChange}
                    placeholder="Select all that apply"
                    title="User type"
                    variant="outlined"
                    className={"modal_input"}
                    style={{ outline: "none" }}
                    isSelect
                    selectOptions={["talent", "freelancer", "all"]}
                  />
                </div>
                <Button
                  text="Upload Icon"
                  primaryNull
                  small
                  onClick={handleButtonClick}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={imageChange}
                />
              </div>
              <div>
                <InputField
                  name={'description'}
                  value={details.description}
                  onChange={handleChange}
                  placeholder="Description"
                  title="Description"
                  variant="outlined"
                  className={"modal_input"}
                  multiline
                  rows={6}
                />
              </div>
              {details.image ? (
                typeof details.image === 'string' ? (
                  <img
                    src={details.image}
                    alt="Badge Preview"
                    style={{ width: "50px", height: "50px", marginTop: "10px" }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(details.image)}
                    alt="Badge Preview"
                    style={{ width: "50px", height: "50px", marginTop: "10px" }}
                  />
                )
              ) : null}
            </div>
            <div className="d-flex gap-3 align-items-center mt-3">
              <Button
                text="Cancel"
                primaryNull={true}
                small={true}
                onClick={() => handleClose()}
              />
              <Button
                text="Save"
                primaryNull={false}
                small={true}
                onClick={send}
                icon={<Save />}
              />

            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default CreateBadge;

import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Modalheader from "../Modalheader";
import "../modal.css";
import Box from "@mui/material/Box";
import InputField from "../../inputField/InputField";
import Button from "@/component/buttons/Button";
import { Save } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 530,
  width: "100%",
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const UpdateSubscriptionPrice = ({
  open,
  details,
  handleClose,
  send,
  handleChange,
  handlePerkChange,
  removePerk,
  addPerk,
  perk
}) => {
  const [showPerks, setShowPerks] = useState(false);
  const [selectedTab, setSelectedTab] = useState("talent");

  const togglePerks = () => {
    setShowPerks((prev) => !prev);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"SET SUBSCRIPTION PLAN"} handleClose={handleClose} />
          <div className="modal_body p-3">
            {/* tabs */}
            <div className="d-flex gap-2">
              <Button
                text={"Talent"}
                primaryNull={selectedTab !== "talent"}
                small={true}
                onClick={() => handleTabChange("talent")}
              />
              <Button
                text={"Recruiter"}
                primaryNull={selectedTab !== "recruiter"}
                small={true}
                onClick={() => handleTabChange("recruiter")}
              />
            </div>
            {/* Input Fields */}
            <div>
              <article className="d-md-flex gap-3 align-items-center mt-3">
                <div className="w-md-full w-50">
                  <InputField
                    name={"nairaAmount"}
                    value={details.nairaAmount}
                    onChange={handleChange}
                    placeholder="Enter Naira amount here..."
                    title="Amount in Naira"
                    variant="outlined"
                    className={"modal_input"}
                    style={{ outline: "none" }}
                  />
                </div>
                <div className="w-md-full w-50">
                  <InputField
                    name={"dollarsAmount"}
                    value={details.dollarsAmount}
                    onChange={handleChange}
                    placeholder=""
                    title="Amount in Dollars"
                    variant="outlined"
                    className={"modal_input"}
                    style={{ outline: "none" }}
                    disabled={true}
                  />
                </div>
              </article>
            </div>
            <div>
              <article className="mt-3">
                <div className="w-full">
                  <InputField
                    name={"plan"}
                    value={details.plan}
                    onChange={handleChange}
                    placeholder="Select all that apply"
                    title="Select plan"
                    variant="outlined"
                    className={"modal_input"}
                    style={{ outline: "none" }}
                    isSelect
                    selectOptions={["talent", "freelancer", "all"]}
                  />
                </div>
              </article>
            </div>
            <div>
              <article className="d-flex gap-3 mt-3 align-items-end">
                <div className="w-75">
                  <InputField
                    name={"perk"}
                    value={perk}
                    onChange={handlePerkChange}
                    placeholder=""
                    title="Perks"
                    variant="outlined"
                    className={"modal_input"}
                    style={{ outline: "none" }}
                  />
                </div>
                <div>
                  <Button
                    icon={<i class="fa-solid fa-circle-plus"></i>}
                    text="Add"
                    primaryNull={true}
                    small={true}
                    onClick={addPerk}
                  />
                </div>
              </article>
            </div>

            {/* Toggle Perks Section */}
            <div className="mt-3">
              {!showPerks && <h6 style={{ color: '#CC5600', fontWeight: 'bold' }} onClick={togglePerks}>See perks</h6>}
              {showPerks && (
                <div className="mt-2">
                  <ul>
                    {details.perks.map((perk, index) => (
                      <li key={index} className="d-flex align-items-center justify-content-between my-1">
                        <span>{perk}</span>
                        <Button
                          text={<i className="fa-solid fa-trash"></i>}
                          primaryNull={true}
                          small={true}
                          onClick={() => removePerk(index)}
                        />
                      </li>
                    ))}
                  </ul>
                  <Button
                    text={"Hide perks"}
                    primaryNull={false}
                    small={true}
                    onClick={togglePerks}
                  />
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div className="d-flex gap-3 align-items-center mt-3">
              <Button
                text="Cancel"
                primaryNull={true}
                small={true}
                onClick={() => handleClose()}
              />
              <Button
                text="Save"
                primaryNull={false}
                small={true}
                onClick={send}
                icon={<Save />}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateSubscriptionPrice;

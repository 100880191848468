import React, { useEffect } from "react";
import { useState } from "react";
import "./css/Referral.css";
import Button from "@/component/buttons/Button";
import CollapsibleTable from "./badgeTable/BadgeTable";
import CsvDownloadButton from "react-json-to-csv";
import InputField from "../components/inputField/InputField";
import Loading from "../components/loading/loading";
import Pagination from "../components/pagination/Pagination";
import api from "../../../services/api";
import CreateBadge from "../components/modal/Badge/CreateBadge";
import AwardBadge from "../components/modal/Badge/AwardBadge";

const Badge = () => {
  const [badges, setBadges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    status: "",
    startDate: "",
    endDate: "",
    name: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const [isCreatingBadge, setIsCreatingBadge] = useState(false);
  const [badgeDetails, setBadgeDetails] = useState({
    name: "",
    image: "",
    description: "",
    userType: "",
  });
  const [editing, setEditing] = useState(false);

  const handleEditClick = (badge) => {
    setEditing(true);
    setBadgeDetails(badge);
    setIsCreatingBadge(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBadgeDetails((prevDetails) => ({
        ...prevDetails,
        image: file,
      }));
    }
  };

  const [isAwardingBadge, setIsAwardingBadge] = useState(false);
  const [awardDetails, setAwardDetails] = useState({
    badgeTitle: "",
    userType: "",
    userName: "",
  });

  const handleCreatingBadgeChange = (e) => {
    const { name, value } = e.target;
    setBadgeDetails((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };
  const handleAwardingBadgeChange = (e) => {
    const { name, value } = e.target;
    setAwardDetails((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const fetchBadges = () => {
    setIsLoading(true);
    const filters = {};
    if (filterOptions.status) {
      filters.status = filterOptions.status;
    }
    if (filterOptions.startDate) {
      filters.startDate = filterOptions.startDate;
    }
    if (filterOptions.endDate) {
      filters.endDate = filterOptions.endDate;
    }
    if (filterOptions.name) {
      filters.name = filterOptions.name;
    }
    api
      .getBadges({
        ...filters,
        page: currentPage,
        limit: itemsPerPage,
      })
      .then((res) => {
        setBadges(res.data.badges);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchBadges();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filterOptions]);

  // pagination
  const totalNumberOfPages = Math.ceil(badges.length / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      name: searchTerm,
    }));
  };

  const createBadge = () => {
    setIsLoading(true);
    if (badgeDetails) {
      const formData = new FormData();
      formData.append("name", badgeDetails.name);
      formData.append("description", badgeDetails.description);
      formData.append("userType", badgeDetails.userType);
      formData.append("picture", badgeDetails.image);

      if (editing) {
        api
          .updateBadge(formData)
          .then((res) => {
            fetchBadges();
            setIsCreatingBadge(false);
            setEditing(false);
          })
          .catch(console.error)
          .finally(() => setIsLoading(false));
      } else {
        api
          .addBadge(formData)
          .then((res) => {
            fetchBadges();
            setIsCreatingBadge(false);
          })
          .catch(console.error)
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <div className="top-container">
            <h2 className="name my-2">Badge</h2>
            <div className="d-flex gap-2 align-items-center">
              <Button
                icon={<i class="fa-solid fa-circle-plus"></i>}
                text="Create Badge"
                primaryNull={true}
                small={true}
                onClick={() => setIsCreatingBadge(!isCreatingBadge)}
              />
              <Button
                icon={<i class="fa-solid fa-ribbon"></i>}
                text="Award Badge"
                small={true}
                onClick={() => setIsAwardingBadge(!isAwardingBadge)}
              />
            </div>
          </div>
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between ">
                <div className="form d-lg-flex align-items-start gap-4 col-lg-10">
                  <div
                    className={`input-search ${
                      filterType === "" && "col-lg-5"
                    } col-lg-4 d-flex py-2 mt-1`}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for users"
                      className="input_text"
                      value={filterOptions.name}
                      onChange={handleSearch}
                    />
                  </div>
                  <div
                    className={`input-filter  ${
                      filterType === "" && "col-lg-5"
                    } col-lg-3 d-flex pt-3`}
                  >
                    <select
                      className="input_text"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <option selected value="">
                        Filter by date range
                      </option>
                      <option value="date">date</option>
                    </select>
                  </div>
                  {filterType !== "" && (
                    <div className="col-lg-3 d-flex align-items-end py-2 my-4 my-lg-0">
                      {filterType === "date" && (
                        <div className="row" style={{ marginTop: "-20px" }}>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ width: "80%" }}
                          >
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"Start date"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    startDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"End date"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    endDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="d-flex gap-3 my-3 my-lg-0">
                    <div className="position-relative">
                      <Button
                        text="Sort"
                        primaryNull={true}
                        small={true}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                      {isOpen && (
                        <div
                          className="position-absolute text-white p-2 rounded mt-2"
                          style={{
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            minWidth: "180px",
                            backgroundColor: "#CC5600",
                          }}
                        >
                          <Button
                            text={`By Date`}
                            primaryNull
                            small
                            onClick={""}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <CsvDownloadButton
                        data={[]}
                        filename={`joblisting-${currentPage}`}
                      >
                        <Button
                          text="Export"
                          primaryNull={false}
                          small={true}
                        />
                      </CsvDownloadButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <CollapsibleTable
                    initialRows={badges}
                    onEdit={handleEditClick}
                  />
                  <Pagination
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalNumberOfPages={totalNumberOfPages}
                  />
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <CreateBadge
        open={isCreatingBadge}
        details={badgeDetails}
        handleChange={handleCreatingBadgeChange}
        handleClose={() => setIsCreatingBadge(false)}
        send={createBadge}
        imageChange={handleImageChange}
      />

      <AwardBadge open={isAwardingBadge} details={awardDetails} handleChange={handleAwardingBadgeChange} handleClose={() => setIsAwardingBadge(false)} />
    </div>
  );
};

export default Badge;

import "./css/Profile.style.sass";
import "./css/Profile.css";
import BreadCrumb from "../../component/bread-crumb/BreadCrumb";
import ProjectCard from "../../component/cards/project-card/ProjectCard";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../services/api";
import service from "../../services";

function Profile() {
  // service.setPageTitle('Profile');
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [appliedJobs, setAppliedJobs] = useState(0)
  const [projectPreview, setProjectPreview] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingImage, setLoadingImage] = useState(true);

  const breadCrumbProp = [
    {
      title: "Users",
      link: "",
      previous: true,
    },
    {
      title: "Profile",
      link: "",
    },
  ];

  useEffect(() => {
    api
      .getTalent(id)
      .then((res) => {
        setIsLoading(true);
        setLoadingImage(true);
        setUser(res.data);
      })
      .catch((err) => { console.log(err); alert(err.data?.message) })
      .finally(() => {
        setIsLoading(false);
        setLoadingImage(false);
      });
    // get applied jobs
    api.getAppliedJobs({ talent: id }).then((res) => { setAppliedJobs(res.data.totalCount); }).catch((res) => console.log(res))
    // get projects
    api.getTalentsProjects(id).then((res) => { setProjectPreview(res.data?.projects) }).catch((res) => console.log(res))
  }, [id]);

  return (
    <div
      id="Recruiter_View_Talent_Profile_Main_Container"
      className="col-lg-10 col-12"
    >
      <div>
        <div className="container-01 py-4">
          <div className="row ">
            <div className="first-col col-12 mb-5 mb-lg-0">
              <div className="outline-wrap" style={{ marginBottom: "40px" }}>
                <BreadCrumb context={breadCrumbProp} />

                <div className="section section-a py-4">
                  {!isLoading && (
                    <div
                      className={`section-a d-lg-flex align-items-center justify-content-between gap-3`}
                    >
                      <div className="row w-100">
                        <div className="col-lg-7 side_profile">
                          <div className="d-flex flex-column flex-lg-row align-items-center gap-3  mb-lg-0">
                            <div className="con-img">
                              <div className="user-img">
                                {!loadingImage && (
                                  <img
                                    src={
                                      user?.profile_picture?.href_link ||
                                      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png"
                                    }
                                    alt=""
                                  />
                                )}

                                {loadingImage && (
                                  <div className={"animated-bg"}></div>
                                )}
                              </div>
                            </div>

                            <div className="user-context d-flex flex-column align-items-center align-items-lg-start text-center gap-2">
                              <div style={{ display: 'flex', gap: '5px' }}>
                                <h1>
                                  {user?.first_name} {user?.last_name}
                                </h1>
                                <img src={user?.isProfileApproved ? "/img/svg/verified.svg" : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
                              </div>
                              <h2>
                                {" "}
                                {user?.title ? user?.title : "No Title Yet"}
                              </h2>
                              <p className="d-flex align-items-center gap-2">
                                <i className="fa-solid fa-location-dot"></i>
                                {user?.location?.resident_state &&
                                  service.titleCase(
                                    user?.location?.resident_state
                                  )}
                                ,{" "}
                                {user?.location?.resident_country &&
                                  service.titleCase(
                                    user?.location?.resident_country
                                  )}
                              </p>
                              {/* <span className="d-flex align-items-center gap-2">
                                {user?.isPremium ? 'Premium User' : 'Regular User'}
                              </span> */}
                              <span className="d-flex align-items-center gap-2">
                                <i class="fa-solid fa-envelope"></i>

                                {user?.email}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="job_number">
                            <h4>Overview</h4>
                            <div className="d-flex gap-3 my-3">
                              <p>
                                Nos of Jobs: <span>{appliedJobs}</span>
                              </p>
                              <h4><Link to={appliedJobs > 0 ? `/admin/view-profile/applied-jobs/${id}` : ''}>View Jobs</Link></h4>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  )}

                  <div className="con-animated-bg">
                    {isLoading &&
                      [1, 1, 1].map((x, y) => (
                        <div className={"animated-bg"} key={y}></div>
                      ))}
                  </div>
                </div>

                {!isLoading && (
                  <div className="section section-b py-4">
                    <h2 className="title mb-3">About {user?.first_name}</h2>
                    <p>
                      {user?.talent_bio && service.transform(user?.talent_bio)}
                    </p>
                  </div>
                )}

                {!isLoading && (
                  <div className="section section-c py-4">
                    <div className="heading d-flex align-items-center justify-content-between mb-3">
                      <h2 className="title">Projects</h2>

                      {projectPreview.length > 7 && (
                        <Link to="/dashboard/projects">
                          <p>See More</p>
                        </Link>
                      )}
                    </div>

                    <div className="body d-flex align-items-center gap-3">
                      {projectPreview.length > 0 ? (
                        projectPreview.map((project) => {
                          const coverImage =
                            project.project_cover_image?.href_link;
                          return (
                            <ProjectCard
                              key={project._id}
                              title={project.project_title}
                              cover={coverImage}
                              id={project._id}
                            />
                          );
                        })
                      ) : (
                        <div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center border-bottom">
                          <img
                            src={"/img/svg/noproject.svg"}
                            alt="no project"
                            style={{ width: "70px" }}
                          />
                          <h5 className="fw-bold">
                            Talent Has No Project added
                          </h5>
                        </div>
                      )}
                    </div>
                    {projectPreview.length > 7 && (
                      <div className="d-flex justify-content-center mt-4">
                        {/* <DotPagination index={0} /> */}
                      </div>
                    )}
                  </div>
                )}
                {/* Experience */}
                {!isLoading && (
                  <div className="section section-e py-4">
                    <div className="heading d-flex align-items-center gap-2">
                      <img
                        src="/img/svg/experience.svg"
                        alt="experience"
                        style={{ width: "25px" }}
                      />
                      <h2 className="title">Experience</h2>
                    </div>
                    {user?.experience?.length > 0 ? (
                      <div>
                        {user?.experience?.length > 0 &&
                          user?.experience.map((x, y) => (
                            <div key={y}>
                              <div className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4">
                                <div className="experience-block mt-3">
                                  <div className="one d-flex align-items-center gap-2 mt-4">
                                    <h2 className="job-title ">
                                      {x?.job_title || `job title`}
                                    </h2>

                                    <div className="tip">
                                      <h5> {x.contract_type || ``}</h5>
                                    </div>
                                  </div>
                                  <p className="two my-3">
                                    {x.company || `company`}
                                  </p>

                                  <p className="three">
                                    {
                                      service.monthNames()[
                                      new Date(x.start_date).getMonth()
                                      ]
                                    }
                                    &nbsp;
                                    {new Date(x.start_date).getFullYear()}
                                    &nbsp;-&nbsp;
                                    {x.end_date === "present" ? (
                                      "Present"
                                    ) : (
                                      <>
                                        {
                                          service.monthNames()[
                                          new Date(x.end_date).getMonth()
                                          ]
                                        }
                                        &nbsp;
                                        {new Date(x.end_date).getFullYear()}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center border-bottom">
                        <img
                          src={"/img/svg/noexp.svg"}
                          alt="no experience"
                          style={{ width: "70px" }}
                        />
                        <h5 className="fw-bold">
                          Talent Has No Work Experience added
                        </h5>
                      </div>
                    )}
                  </div>
                )}
                {/* Education */}
                {!isLoading && (
                  <div className="section section-f py-4">
                    <div className="heading d-flex align-items-center gap-2">
                      <img
                        src="/img/svg/education.svg"
                        alt="education"
                        style={{ width: "25px" }}
                      />
                      <h2 className="title">Education</h2>
                    </div>
                    {user?.education?.length > 0 ? (
                      <div>
                        {user?.education?.length > 0 &&
                          user?.education.map((x, y) => (
                            <div key={y}>
                              <div className="con-experience-block d-lg-flex align-items-start justify-content-between gap-4 mt-3">
                                <div className="experience-block mt-4">
                                  <div className="one d-flex align-items-center gap-2 my-3">
                                    <h2>{x?.school}</h2>
                                  </div>
                                  <p className="two">{x?.degree}</p>
                                  <p className="two">{x?.field_of_study}</p>

                                  <p className="three">
                                    {new Date(x.start_date).getFullYear()}
                                    &nbsp;-&nbsp;
                                    {x.end_date === "present" ? (
                                      "Present"
                                    ) : (
                                      <>
                                        {x.end_date
                                          ? new Date(x.end_date).getFullYear()
                                          : `Date`}
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center border-bottom">
                        <img
                          src={"/img/svg/noedu.svg"}
                          alt="no experience"
                          style={{ width: "70px" }}
                        />
                        <h5 className="fw-bold">
                          Talent Has No Education added
                        </h5>
                      </div>
                    )}
                  </div>
                )}

                {/* Skills */}

                {!isLoading && (
                  <div className="section section-g py-4">
                    <div className="heading d-flex align-items-center gap-2">
                      <img
                        src="/img/svg/experience.svg"
                        alt="experience"
                        style={{ width: "25px" }}
                      />

                      <h2 className="title">Skills</h2>
                    </div>
                    {user?.skills?.map((x, y) => {
                      return (
                        <div className="d-flex gap-3 align-items-center my-3">
                          <p
                            key={y}
                            className="text-capitalize"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {x?.name}
                          </p>
                          <p>-</p>

                          <p className="text-truncate text-capitalize">
                            {" "}
                            with {x?.experience_years} {""}
                            years of Experience
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}

                {!isLoading && (
                  <div className="section section-g py-4">
                    <div className="heading d-flex align-items-center gap-2">
                      <img
                        src="/img/svg/experience.svg"
                        alt="experience"
                        style={{ width: "25px" }}
                      />

                      <h2 className="title">Expected Salary</h2>
                    </div>

                    <div className="d-flex my-3">
                      <h2 className="expected_pay_currency">
                        {user?.current_salary_currency ?? ""}
                        {user.role === 'freelancer' ? user?.expected_income : user.expected_salary}
                      </h2>
                    </div>
                  </div>
                )}

                {/* Certificates */}
                {!isLoading && (
                  <div className="section section-g py-4">
                    <div className="heading d-flex align-items-center gap-2">
                      <img
                        src="/img/svg/certificate.svg"
                        alt="certificate"
                        style={{ width: "25px" }}
                      />
                      <h2 className="title">Certificates</h2>
                    </div>

                    {user?.certificates?.length > 0 ? (
                      <div className="my-3">
                        {
                          <section className="py-2 row">
                            {user?.certificates?.length > 0 &&
                              user?.certificates.map((x, y) => (
                                <div key={y} className="col-lg-6">
                                  <h2
                                    className="text-capitalize"
                                    style={{ fontSize: "1.2rem" }}
                                  >
                                    {x.issuer}
                                  </h2>

                                  <div
                                    className="d-flex justify-content-between align-items-center border border-4 border-dark mb-2 mt-1 py-3 px-3"
                                    style={{ borderRadius: "10px" }}
                                  >
                                    <div style={{ width: "80%" }}>
                                      <p
                                        className="text-truncate"
                                        style={{ maxWidth: "90%" }}
                                      >
                                        {x.file_name}
                                      </p>
                                      <p
                                        className="text-thin"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: ".8rem",
                                        }}
                                      >
                                        Uploaded:{""}
                                        {new Date(
                                          x.uploadDate
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                      </p>

                                      <p className="sub-title mt-1">
                                        Issuer: {x.issuer}
                                      </p>
                                    </div>
                                    <div
                                      type="button"
                                      onClick={() => {
                                        try {
                                          // Perform your download logic here
                                          const downloadLink =
                                            document.createElement("a");
                                          downloadLink.setAttribute(
                                            "target",
                                            "_"
                                          );
                                          downloadLink.href = x.href_link;
                                          downloadLink.download = x.file_name;
                                          downloadLink.click();
                                        } catch (error) {
                                          console.error(
                                            "Error downloading the file:",
                                            error
                                          );
                                        }
                                      }}
                                    >
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>

                                  <h5 className="sub-title mt-1">
                                    Year issued:{" "}
                                    {new Date(x.issued_date).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                      }
                                    )}
                                  </h5>
                                </div>
                              ))}
                          </section>
                        }
                      </div>
                    ) : (
                      <div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center ">
                        <img
                          src={"/img/svg/noexp.svg"}
                          alt="no experience"
                          style={{ width: "70px" }}
                        />
                        <h5 className="fw-bold">
                          Talent Has No Certificate added
                        </h5>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

import React, { useState, useEffect } from "react";
import Button from "@/component/buttons/Button";
import AppliedJobListingTable from './appliedJobsListingTable/AppliedJobListingTable'
import './css/UserAppliedJob.css'
import api from '@/services/api';
import BreadCrumb from "../../../component/bread-crumb/BreadCrumb";
import Loading from "../../dashboardreview/components/loading/loading";
import { useParams } from "react-router-dom";


const UserAppliedJobs = () => {
 const { id } = useParams();
 const [appliedJobs, setAppliedJobs] = useState([])
 const [loading, setLoading] = useState(false);
 const [search, setSearch] = useState('');

 const breadCrumbProp = [
  {
   title: "Users",
   link: "",
   previous: true,
  },
  {
   title: "Profile",
   link: "",
   previous: true,
  },
  {
   title: "Applied Jobs",
   link: "",
  },
 ];


 useEffect(() => {
  setLoading(true);
  api.getAppliedJobs({ talent: id }).then((res) => {
   setAppliedJobs(res.data.applicants)
  }).catch((res) => console.log(res)).finally(() => setLoading(false))
 }, [id]);

 const handleSearch = (event) => {
  const searchTerm = event.target.value;
  setSearch(searchTerm);
 };

 // const filterByName = (data, searchText) => {
 //  const lowerSearchText = searchText.toLowerCase();
 //  return data?.filter(item => {

 //   const position = item.position.toLowerCase();


 //   return position.includes(lowerSearchText)
 //  });
 // };
 // const filteredJobs = filterByName(appliedJobs, search)
 return (
  <div className="col-lg-10  col-12 py-3">
   <div className="row">
    <div className="col-12">
     <BreadCrumb context={breadCrumbProp} />
    </div>
    <div className="col-12">
     <div className="table-container">
      <div className="header_con mb-4 feedback-header py-3">
       <div className="row justify-content-between">
        <div className="form d-flex align-items-end   gap-5 col-lg-10">
         <div className="input-search col-lg-5 d-flex py-2">
          <div className="px-2">
           <img src="/search.svg" alt="" />
          </div>
          <input
           type="search"
           placeholder="Search for users"
           className="input_text"
           value={search}
           onChange={handleSearch}
          />
         </div>
         <div className="input-filter col-lg-5 d-flex py-2">
          <select className="input_text">
           <option selected>Filter by</option>
           <option value="1">One</option>
           <option value="2">Two</option>
           <option value="3">Three</option>
          </select>
         </div>

         <div className="d-flex gap-3">
          <div>
           <Button text="Sort" primaryNull={true} small={true} />
          </div>
          <div>
           <Button text="Export" primaryNull={false} small={true} />
          </div>
         </div>
        </div>
       </div>
      </div>

      <div>
       {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <Loading />
        </div>) :
        <AppliedJobListingTable loading={loading} jobs={appliedJobs} />
       }
      </div>
     </div>

    </div>
   </div>
  </div>
 )
}

export default UserAppliedJobs
import React, { createContext, useContext, useState } from 'react';
import { io } from 'socket.io-client';
import service from '../services';


const accessToken = service.getTokensLocalStorage()?.access?.token;
const socket = io('https://frontters-admin-dashboard.onrender.com/conversations', {
  auth: { token: accessToken },
});
const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);


  socket.on('connect', () => {
    console.log('Connected to the socket server');
  });


  socket.on('receive-message', (newMessage) => {
    console.log('Message received:', newMessage);
    setNotifications((prev) => [...prev, newMessage]);
  });

  return (
    <NotificationContext.Provider value={{ notifications, socket }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);

import './css/ProjectCard.css';

function ProjectCard({ width, title, cover, id }) {
	return (
		<div className="projectCard" style={{ width: width || '280px' }}>
			<div className={`header d-flex align-items-center justify-content-between`}>
				<h2>{title}</h2>
				<div className="d-flex align-items-center gap-3">
					{/* <div>
						<i className="fa-regular fa-pen-to-square"></i>
					</div> */}
				</div>
			</div>
			<div className={'body'}>
				<div className={'img'}>
					<img src={cover} alt={title} style={{ width: '100%' }} />
				</div>
				<article>
					{/* <div className={`arrow-btn`}>
						<i className="fa-solid fa-arrow-right"></i>
					</div> */}
				</article>
			</div>
		</div>
	);
}


export default ProjectCard;

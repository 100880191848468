import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, Fragment } from "react";
// import { Rating } from "@mui/material";
// eslint-disable-next-line
import { KeyboardArrowDown, KeyboardArrowUp, Star } from "@mui/icons-material";
import Button from "@/component/buttons/Button";
import BadgeDetails from "./BadgeDetails";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { formatDate } from "@/component/formatter";
import api from "../../../../services/api";

function Row({ row, onDelete, editClick }) {
  const [open, setOpen] = useState(false);

  const deleteBadge = () => {
    api
      .deleteBadge(row?._id)
      .then(() => onDelete(row?._id))
      .catch(console.error);
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            width: "20px",
          }}
        >
          {<img src={row?.image} alt="dont know" />}
        </TableCell>
        <TableCell component="th" className="custom-cell">
          {row?.name}
        </TableCell>
        <TableCell className="custom-cell">{row?.userCount}</TableCell>
        <TableCell className="custom-cell">
          {formatDate(row?.createdAt)}
        </TableCell>
        <TableCell className="custom-cell">
          <p
            style={{
              backgroundColor:
                row?.userType === "talent" ? "#FFD466" : "#B7E6CA",
              borderRadius: "10px",
              padding: "3px 10px",
              textAlign: "center",
            }}
            className="text_overflow"
          >
            {row?.userType}
          </p>
        </TableCell>
        <TableCell className="custom-cell">
          <div className="d-flex gap-2 align-items-center">
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{
                color: "#CC5600",
                fontSize: "14px",
              }}
              onClick={() => setOpen(!open)}
            >
              View Details {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <div className="d-flex gap-2">
              <DriveFileRenameOutlineIcon
                sx={{ color: "#CC5600", cursor: "pointer" }}
                onClick={() => editClick(row)}
              />
              <DeleteIcon
                sx={{ fill: "#E23636", cursor: "pointer" }}
                onClick={deleteBadge}
              />
            </div>
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="hidden_details">
              <>
                <BadgeDetails user={row} />
                <form className="form_con">
                  <hr />
                  <div className="my-3 closetoggle">
                    <Button
                      text="View Less"
                      primaryNull
                      small
                      onClick={() => setOpen(!open)}
                    />
                  </div>
                </form>
              </>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function CollapsibleTable({ initialRows, onEdit }) {
  const [rows, setRows] = useState(initialRows);

  const handleDelete = (id) => {
    setRows(rows.filter((row) => row._id !== id));
  };

  return (
    <TableContainer>
      <Table aria-label="collapsible table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Name</TableCell>
            <TableCell>Badge title</TableCell>
            <TableCell>No. of winners</TableCell>
            <TableCell>Date created</TableCell>
            <TableCell>User type</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <Row
              key={index}
              row={row}
              onDelete={handleDelete}
              editClick={onEdit}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

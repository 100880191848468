import { Route, Routes, useLocation } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import Footer from "./views/_shared/footer/Footer";
import Navbar from "./views/_shared/navbar/Navbar";
import Dashboardreview from "./views/dashboardreview/Dashboardreview";
import TalentDashoard from "./views/dashboardreview/talent-dashboard/TalentDashboard";
import RecruitersDashbord from "./views/dashboardreview/recruiters-dashboard/RecruitersDashboard";
import JobDashboard from "./views/dashboardreview/job-dashboard/JobDashboard";
import Feedback from "./views/dashboardreview/feedback/Feedback";
import JobListing from "./views/dashboardreview/jobListing/JobListing";
import Updates from "./views/dashboardreview/updates/Updates";
import CreateUpdate from "./views/dashboardreview/updates/createUpdate/CreateUpdate";
import ProfileRecruiter from "./views/profile-recruiter/ProfileRecruiter";
import AdminProfile from "./views/dashboardreview/admin-profile/AdminProfile";

import Sidebar from "./views/dashboardreview/components/sidebar/Sidebar";

import Button from "./component/buttons/Button";
import Profile from "./views/profile/Profile";
import SignUp from "./views/signup/SignUp";
import api from "./services/api";

import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import service from "./services";
import JobDetail from "./views/dashboardreview/jobdetails/JobDetail";
import PreviewUpdate from "./views/dashboardreview/updates/previewUpdate/PreviewUpdate";
import Users from "./views/dashboardreview/users-details/Users";
import UserAppliedJobs from "./views/profile/user-applied-jobs/UserAppliedJobs";
import JobApplicants from "./views/dashboardreview/jobdetails/jobApplicants/JobApplicants";
import AdminDashboard from "./views/dashboardreview/admin-dashboard/AdminDashboard";
import SuperAdminDashboard from "./views/dashboardreview/superAmin-dashboard/SuperAdminDashboard";
import Skills from "./views/dashboardreview/skills/Skills";
import ReportedJob from "./views/dashboardreview/reported-job/ReportedJob";
import InitiateChat from "./views/chat/InitiateChat";
import CustomMessages from "./views/chat/custom-messages/CustomMessages";
import Conversations from './views/chat/Conversations'
import Referral from "./views/dashboardreview/coupons/referral-coupons/Referral";
import Promo from "./views/dashboardreview/coupons/promo-coupons/Promo";
import Badge from "./views/dashboardreview/badge/Badge";
import Advertisement from "./views/dashboardreview/advertisement/Advertisement";
import Subscriptions from "./views/dashboardreview/subscriptions/Subscriptions";



function App() {
  const location = useLocation();
  const routesWithHiddenSideBar = ["/"];
  const secondrouteWithHiddenSideBar = ["/signin"];
  const iSidebarbarHidden =
    routesWithHiddenSideBar.includes(location.pathname) ||
    secondrouteWithHiddenSideBar.includes(location.pathname);
  const [hasToken, setHasToken] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [user, setUser] = useState('John');

  const navigate = useNavigate();

  const authChecker = () => {
    if (service.getTokensLocalStorage() === null) authHandler(false);
  };

  const authHandler = (boolean) => {
    setHasToken(boolean);
    setIsAuthenticated(boolean);
  };

  useEffect(() => {
    api
      .getAdmin()
      .then((res) => {
        setUser(res.data);
        sessionStorage.setItem("adminUser", JSON.stringify(res.data));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    authChecker();
  });
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("picture", file);
    api
      .uploadPicture(formData)
      .then()
      .catch((err) => console.log(err));
  };

  const handleLogout = () => {
    service.removeTokensLocalStorage();
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="container_hold">
      <Navbar
        rightContent={
          <div className="d-flex align-items-center justify-content-between gap-3 ">
            {isAuthenticated && hasToken && (
              <div className="profile-img-container">
                <img
                  src={
                    user?.profile_picture?.href_link ||
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png"
                  }
                  alt="profile"
                />

                {/* <button onClick={handleUpload}>Upload Image</button> */}
                <input
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  onChange={handleImageChange}
                  className="upload-field"
                />
              </div>
            )}
            {isAuthenticated && hasToken && (
              <Button small text="Logout" onClick={handleLogout} />
            )}
          </div>
        }
      />

      <div className="container all-conatiners">
        <div style={{width:'100%', display:'flex', justifyContent:'space-between', gap:'40px', marginTop:'40px'}}>
        {!iSidebarbarHidden && <Sidebar />}

          <div style={{width:'100%',padding:'10px 0 0 15px'}}>
            <Routes>
              <Route path="/" element={<SignUp isLogin={authHandler} />} />
              <Route
                element={
                  isAuthenticated && hasToken ? (
                    <>{<Outlet />}</>
                  ) : (
                    <Navigate to="/" />
                  )
                }
              >
                <Route
                  path="/admin/dashboard"
                  element={<Dashboardreview name={user?.firstName} />}
                />
                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/talents" element={<TalentDashoard />} />
                <Route
                  path="/admin/recruiters"
                  element={<RecruitersDashbord />}
                />
                <Route path="/admin/admins" element={<AdminDashboard />} />
                <Route
                  path="/admin/super-admins"
                  element={<SuperAdminDashboard />}
                />
                <Route path="/admin/skills" element={<Skills />} />
                <Route path="/admin/jobs" element={<JobDashboard />} />
                <Route path="/admin/view-profile/:id" element={<Profile />} />
                <Route
                  path="/admin/admin-profile/:id"
                  element={<AdminProfile />}
                />
                <Route
                  path="/admin/view-profile/applied-jobs/:id"
                  element={<UserAppliedJobs />}
                />
                <Route
                  path="/admin/view-recriuter/:id"
                  element={<ProfileRecruiter />}
                />
                <Route path="/admin/jobdetails/:id" element={<JobDetail />} />
                <Route
                  path="/admin/jobdetails/applicants/:id"
                  element={<JobApplicants />}
                />
                <Route path="/admin/feedback" element={<Feedback />} />
                <Route path="admin/advert-room" element={<Advertisement />} />
                <Route path="/admin/reports" element={<ReportedJob />} />
                <Route path="/admin/jobListing" element={<JobListing />} />
                <Route path="/admin/referral-coupons" element={<Referral />} />
                <Route path="/admin/promotion-coupons" element={<Promo />} />
                <Route path="/admin/subscriptions" element={<Subscriptions />} />
                <Route path="/admin/updates" element={<Updates />} />
                <Route path="/admin/conversations" element={<InitiateChat />} />
                <Route path="/admin/badges" element={<Badge />} />
                <Route path="/admin/custom-messages" element={<CustomMessages />} />
                <Route path="/admin/chat-history" element={<Conversations />} />
                <Route path="/admin/chat-history/:id" element={<Conversations />} />
                <Route path="/admin/create-update" element={<CreateUpdate />} />
                <Route path="/admin/previewupdate" element={<PreviewUpdate />} />
              </Route>
            </Routes>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;

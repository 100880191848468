import React from 'react'
import { Cancel, Person } from "@mui/icons-material";
import { IconButton } from '@mui/material';


const Modalheader = ({headText,handleClose}) => {
  return (
    <div className="modal_header" style={{padding:'0 4px'}}>
    <Person
      sx={{
        fontSize: "12px",
      }}
    />
    <small style={{fontSize:'10px'}}>{headText}</small>
    <IconButton onClick={handleClose}  sx={{
        color:'#fff'
    }} aria-label="add to shopping cart">
    <Cancel
      sx={{
        fontSize: "12px",
      }}
      
    />
    </IconButton>
    
  </div>
  )
}

export default Modalheader

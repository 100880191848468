import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, Fragment } from "react";
// import { Rating } from "@mui/material";
// eslint-disable-next-line 
import { KeyboardArrowDown, KeyboardArrowUp, Star } from "@mui/icons-material";
import Button from "@/component/buttons/Button";
import PromotionDetails from "./PromotionDetails";
import { Switch, styled } from "@mui/material";
import { formatDate } from "@/component/formatter";
import api from "../../../../../services/api";



const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: 'grey', // Rest state color
    '&.Mui-checked': {
      color: '#CB5600', // Checked state color
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#CB5600', // Checked state track color
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'grey', // Rest state track color
  },
}));
function Row({ row }) {
  const [open, setOpen] = useState(false);
  const [closePromo, setClosePromo] = useState(row?.isActive)

  const handleCloseCoupon = (event) => {
    if(row?.isActive === false){
      api.activatePromo(row?._id).then(res => { setClosePromo(!row?.isActive); }).catch(console.error)
    }else{
      api.deactivatePromo(row?._id).then(res => {setClosePromo(!row?.isActive); }).catch(console.error)
    }
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "180px",
            whiteSpace: "nowrap",
          }}
        >
          {row?.code}
        </TableCell>
        <TableCell className="custom-cell">
          {row?.userCount}
        </TableCell>
        <TableCell className="custom-cell">
          {row?.discount}%
        </TableCell>
        <TableCell className="custom-cell">
          <p className="text_overflow ">{formatDate(row?.createdAt)}</p>
        </TableCell>
        <TableCell className="custom-cell">
          <span className="tag2">{row?.userType}</span>{" "}
        </TableCell>
        <TableCell className="custom-cell">
          <div>
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{
                color: "#CC5600",
                fontSize: "14px",
              }}
              onClick={() => setOpen(!open)}
            >
              View Details {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <CustomSwitch onChange={handleCloseCoupon} checked={closePromo} />
          </div>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="hidden_details">
              <>
                <PromotionDetails user={row} />
                <form className="form_con">
                  <hr />
                  <div
                    className="my-3 closetoggle"
                  >
                    <Button
                      text="View Less"
                      primaryNull
                      small
                      onClick={() => setOpen(!open)}
                    />
                  </div>
                </form>
              </>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function CollapsibleTable({ rows }) {
  return (
    <TableContainer>
      <Table aria-label="collapsible table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Promo Code</TableCell>
            <TableCell>No. of Users</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>User type</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return <Row row={row} key={index} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

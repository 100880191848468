import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, Fragment } from "react";
// eslint-disable-next-line
import Button from "@/component/buttons/Button";
import { formatDate } from "@/component/formatter";

function Row({ row }) {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            color: "#CC5600",
            textDecoration: "underline",
          }}
        >
          {row?.friend_email}
        </TableCell>
        <TableCell className="custom-cell">
          {formatDate(row?.createdAt)}
        </TableCell>
        <TableCell className="custom-cell">
          {" "}
          <span
            style={{
              backgroundColor: "#B7E6CA",
              borderRadius: "10px",
              padding: "3px 10px",
              textAlign: "center",
            }}
          >
            {row?.user_type !== null ? row?.user_type : "Talent"}
          </span>{" "}
        </TableCell>
        <TableCell
          className="custom-cell"
          component="th"
          scope="row"
        >
          {row?.talent_percentage}
        </TableCell>
        <TableCell
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            color: "#CC5600",
            textDecoration: "underline",
          }}
        >
          {row?.referrer
            ? row?.referrer?.first_name + " " + row?.referrer?.last_name
            : "Deleted Talent"}
        </TableCell>
        <TableCell
          className="custom-cell"
          style={{
            maxWidth: "50px",
            whiteSpace: "nowrap",
          }}
        >
          <span
            style={{
              backgroundColor: "#FECFFF",
              borderRadius: "10px",
              padding: "3px 10px",
              textAlign: "center",
              fontSize: "11px",
            }}
          >
            {row?.status}
          </span>{" "}
        </TableCell>
        <TableCell className="custom-cell">
          <Button
            text={"Not Payable"}
            darkFill
            small
            disabled
            onClick={() => setOpen(!open)}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function CollapsibleTable({ rows }) {
  return (
    <TableContainer>
      <Table aria-label="collapsible table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Referee</TableCell>
            <TableCell>Date Updated</TableCell>
            <TableCell>Talent Type</TableCell>
            <TableCell>Percen- tage</TableCell>
            <TableCell>Referer</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return <Row row={row} key={index} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { useEffect, useState } from "react";
import Advert from "../components/Advert/Advert";
import { CircularProgress,  } from "@mui/material";
import Topbar from "../components/topbar/Topbar";
import Button from "@/component/buttons/Button";
import api from "../../../services/api";

const Advertisement = () => {
  const [rooms, setRooms] = useState([])
  const [activeRoom, setActiveRoom] = useState('')
  const [spaces, setSpaces] = useState([])

  useEffect(() => {
    api.getRooms()
      .then((res) => {
        const reversedRooms = res.data.reverse();
        setRooms(reversedRooms);

        const talentRoom = reversedRooms.find(room => room.name === 'talent');
        if (talentRoom) {
          setActiveRoom(talentRoom._id);
          fetchSpaces(talentRoom._id);
        }
      })
      .catch(console.error);
  }, []);

  // Fetch spaces whenever the active room changes
  useEffect(() => {
    if (activeRoom) {
      fetchSpaces(activeRoom);
    }
  }, [activeRoom]);

  const fetchSpaces = (roomId) => {
    setLoading(true)
    api.getRoomSpaces(roomId)
      .then((res) => setSpaces(res.data))
      .catch(console.error).finally(() => setLoading(false))
  };

  const [loading, setLoading] = useState(false);



  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <Topbar name={"Advert Room"} />

          <div className="bg-white p-2">
            <hr />
            <article>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className=" my-2">{"Advert Space"}</h3>
                <div className="d-flex gap-3 align-items-center">
                  {rooms.map((room) => (
                    <Button
                      key={room._id}
                      text={room.name.charAt(0).toUpperCase() + room.name.slice(1)}
                      primaryNull={activeRoom !== room._id}
                      small
                      onClick={() => setActiveRoom(room._id)}
                    />
                  ))}
                </div>
              </div>
            </article>
            <div className="">
              {/* spaces */}
              {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                  <CircularProgress />
                </div>
              ) : <div className="table-con table-responsive old_table">
                {spaces.map((space) => (
                  <div key={space._id} className="my-3">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>{space.name}</p>
                    <div className="d-flex flex-wrap gap-4">
                      {["desktop", "tablet", "mobile"].map((device) => (
                        <article key={space.imagePlaceholders[device]._id}>
                          <small>{device.charAt(0).toUpperCase() + device.slice(1)}</small>
                          <Advert spaceId={space._id} placeholder={space.imagePlaceholders[device]} slot={device} onSuccess={() => fetchSpaces(activeRoom)} />
                        </article>
                      ))}
                    </div>
                  </div>
                ))}
              </div>}

            </div>

            {/* advert activity */}
            {/* <div className="">
              <div className="table-container top_radius ">
                <div className="header_con my-3">
                  <h4>Advert Activity</h4>

                  <div className="export_filter my-4">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-8">
                        <div className="row gap-3">
                          <div className="col-lg-4">
                            <InputField
                              title={"Export From"}
                              type={"date"}
                              value={filterOptions.startDate}
                              onChange={(e) => {
                                setFilterOptions((prevOptions) => ({
                                  ...prevOptions,
                                  startDate: e.target.value,
                                }));
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <InputField
                              title={"Export To"}
                              type={"date"}
                              value={filterOptions.endDate}
                              onChange={(e) => {
                                setFilterOptions((prevOptions) => ({
                                  ...prevOptions,
                                  endDate: e.target.value,
                                }));
                              }}
                            />
                          </div>
                          <div className="col-lg-3">
                            <FormControlLabel
                              value="end"
                              control={
                                <Checkbox
                                  sx={{
                                    fontSize: "20px",
                                    color: "#121212",
                                    "&.Mui-checked": {
                                      color: "#CC5600",
                                    },
                                  }}
                                />
                              }
                              label="Export all"
                              labelPlacement="end"
                              sx={{
                                fontSize: "90px",
                                color: "#121212",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 d-flex justify-content-end">
                        <Button
                          text="Export"
                          primaryNull={false}
                          small={true}
                          onClick={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loading />
                    </div>
                  ) : (
                    <OverViewTable
                      goToNextPage={goToNextPage}
                      goToPreviousPage={goToPreviousPage}
                      totalNumberOfPages={totalNumberOfPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      rows={userActivities}
                    />
                  )}
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;

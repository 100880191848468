import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../ContextApi/NotificationProvider';

const Notification = () => {
	const { notifications, setNotifications } = useNotifications();
	const [showDropdown, setShowDropdown] = useState(false);
	const [unreadCount, setUnreadCount] = useState(notifications.length);
	const navigate = useNavigate()


	useEffect(() => {
		// Update unread count whenever notifications change
		setUnreadCount(notifications.length);
	}, [notifications]);

	const handleBellClick = () => {
		// Toggle the dropdown and reset the unread count to zero
		setShowDropdown(!showDropdown);
		setUnreadCount(0);
	};

	const handleNotificationClick = (notification) => {
		setUnreadCount((prev) => prev - 1);
		const { recruiter, talent } = notification.conversationId;
		setNotifications((prev) => prev.filter((n) => n._id !== notification._id));
		navigate(`/admin/chat-history/${recruiter}/${talent}`);
	};
	

	return (
		<div style={{ position: 'relative' }}>
			<i className="fa-solid fa-bell" style={{ fontSize: '1.8rem', cursor: 'pointer' }} onClick={handleBellClick}>
				{unreadCount > 0 && (
					<span
						style={{
							position: 'absolute',
							top: '-5px',
							right: '-5px',
							backgroundColor: 'red',
							color: 'white',
							borderRadius: '50%',
							padding: '0.2rem 0.5rem',
							fontSize: '0.8rem',
						}}>
						{unreadCount}
					</span>
				)}
			</i>

			{showDropdown && (
				<div
					style={{
						position: 'absolute',
						top: '2.5rem',
						right: '0',
						backgroundColor: 'white',
						boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
						borderRadius: '5px',
						width: '200px',
						zIndex: 1,
					}}>
					{notifications.length === 0 ? (
						<p style={{ padding: '1rem', textAlign: 'center' }}>No notifications</p>
					) : (
						<ul style={{ listStyleType: 'none', margin: 0, padding: '0.5rem' }}>
							{notifications.map((notification, index) => (
								<li
									key={index}
									style={{ padding: '0.5rem 0', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
									onClick={() => handleNotificationClick(notification)}
								>
									{`New message from ${notification.sender}`}
								</li>
							))}
						</ul>
					)}
				</div>
			)}
		</div>
	);
};

export default Notification;

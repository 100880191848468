import React, { useEffect } from "react";
import { useState } from "react";
import "./css/Referral.css";
import Button from "@/component/buttons/Button";
import CollapsibleTable from "./subsTable/SubsTable";
import CsvDownloadButton from "react-json-to-csv";
import InputField from "../components/inputField/InputField";
import UpdateSubscriptionPrice from "../components/modal/Suscription price update/UpdateSubscriptionPrice";
import Loading from "../components/loading/loading";
import Pagination from "../components/pagination/Pagination";
import api from "../../../services/api";
import axios from "axios";


const Subscriptions = () => {
  const [promos, setPromos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    status: "",
    startDate: "",
    endDate: "",
    plan: "",
  });
  // const [isOpen, setIsOpen] = useState(false);

  const [updateCoupon, setUpdateCoupon] = useState(false);
  const [newPerk, setNewPerk] = useState("");

  const [formDetails, setFormDetails] = useState({
    nairaAmount: 0,
    dollarsAmount: "",
    plan: "",
    perks: [],
  });

  const [exchangeRate, setExchangeRate] = useState(null); // Store exchange rate

  // Custom debounce function
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };


  useEffect(() => {
    // Function to fetch exchange rates
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(
          "https://v6.exchangerate-api.com/v6/eb683bdf9f93c18fc88df6a7/latest/USD"
        );
        const newDollarRate = response.data.conversion_rates.NGN;
        setExchangeRate(newDollarRate); // Save exchange rate in state
      } catch (err) {
        console.error(err); // Log error for debugging
      }
    };

    fetchExchangeRate(); 
  }, []);

  const calculateDollar = (nairaAmount) => {
    if (exchangeRate) {
      setFormDetails((prev) => ({
        ...prev,
        dollarsAmount: (nairaAmount / exchangeRate).toFixed(2),
      }));
    }
  };

  // Create debounced version of calculateDollar
  const debouncedCalculateDollar = debounce(calculateDollar, 1500);



  // Function to handle adding a new perk
  const handlePerkAddition = () => {
    if (newPerk.trim() !== "") {
      setFormDetails((prevDetails) => ({
        ...prevDetails,
        perks: [...prevDetails.perks, newPerk],
      }));
      setNewPerk("");
    }
  };

  // Function to handle removing a perk by index
  const handlePerkRemoval = (indexToRemove) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      perks: prevDetails.perks.filter((_, index) => index !== indexToRemove), // Remove perk by index
    }));
  };

  const handleSubscriptionChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "nairaAmount") {
      debouncedCalculateDollar(value);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const filters = {};
    if (filterOptions.status) {
      filters.status = filterOptions.status;
    }
    if (filterOptions.startDate) {
      filters.startDate = filterOptions.startDate;
    }
    if (filterOptions.endDate) {
      filters.endDate = filterOptions.endDate;
    }
    if (filterOptions.plan) {
      filters.plan = filterOptions.plan;
    }
    const fetchPromos = () => {
      api
        .getPromos({
          ...filters,
          page: currentPage,
          limit: itemsPerPage,
        })
        .then((res) => {
          setPromos(res.data.promoCoupons)
        })
        .catch((res) => console.log(res))
        .finally(() => setIsLoading(false));
    };

    fetchPromos();
  }, [currentPage, filterOptions, itemsPerPage]);

  // pagination
  const totalNumberOfPages = Math.ceil(promos.length / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      code: searchTerm,
    }));
  };

  const createSubscription = () => {
    setIsLoading(true)
    if (formDetails) {
      console.log(formDetails)
    }
  }

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <div className="top-container">
            <h2 className="name my-2">Subscriptions</h2>
            <div className="d-flex gap-2 align-items-center">
              <Button
                icon={<i class="fa-solid fa-circle-plus"></i>}
                text="Change Subscription Plan"
                primaryNull={true}
                small={true}
                onClick={() => setUpdateCoupon(!updateCoupon)}
              />
            </div>
          </div>
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between ">
                <div className="form d-lg-flex align-items-start gap-4 col-lg-10">
                  <div
                    className={`input-search ${filterType === "" && "col-lg-5"
                      } col-lg-4 d-flex py-2 mt-1`}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for plans"
                      className="input_text"
                      value={filterOptions.code}
                      onChange={handleSearch}
                    />
                  </div>
                  <div
                    className={`input-filter  ${filterType === "" && "col-lg-5"
                      } col-lg-3 d-flex pt-3`}
                  >
                    <select
                      className="input_text"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <option selected value="">
                        Filter by date range
                      </option>
                      <option value="date">date</option>
                    </select>
                  </div>
                  {filterType !== "" && (
                    <div className="col-lg-3 d-flex align-items-end py-2 my-4 my-lg-0">
                      {filterType === "date" && (
                        <div className="row" style={{ marginTop: "-20px" }}>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ width: "80%" }}
                          >
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"Start date"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    startDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"End date"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    endDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="d-flex gap-3 my-3 my-lg-0">
                    {/* <div className="position-relative">
                      <Button
                        text="Sort"
                        primaryNull={true}
                        small={true}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                      {isOpen && (
                        <div
                          className="position-absolute text-white p-2 rounded mt-2"
                          style={{
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            minWidth: "180px",
                            backgroundColor: "#CC5600",
                          }}
                        >
                          <Button
                            text={`By Date`}
                            primaryNull
                            small
                            onClick={""}
                          />
                        </div>
                      )}
                    </div> */}
                    <div>
                      <CsvDownloadButton
                        data={promos}
                        filename={`subscriptions-${currentPage}`}
                      >
                        <Button
                          text="Export"
                          primaryNull={false}
                          small={true}
                        />
                      </CsvDownloadButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <CollapsibleTable rows={promos} />
                  <Pagination
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalNumberOfPages={totalNumberOfPages}
                  />
                </>
              )}
            </>
          </div>
          <UpdateSubscriptionPrice
            open={updateCoupon}
            details={formDetails}
            handleChange={handleSubscriptionChange}
            handleClose={() => setUpdateCoupon(false)}
            send={createSubscription}
            perk={newPerk}
            handlePerkChange={(e) => setNewPerk(e.target.value)}
            addPerk={handlePerkAddition}
            removePerk={handlePerkRemoval}
          />
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;

import React, { useEffect, useRef, useState } from "react";
import ChatList from "./Chatlist";
import ChatWindow from "./ChatWindow";
import service from "../../services";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import { useNotifications } from "../../ContextApi/NotificationProvider";

const Conversations = () => {
  service.setPageTitle("Conversations");
  const top = useRef(null);
  useEffect(() => service.goTo(top), []);
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [userType, setUserType] = useState("");
  const [userTypeDetails, setUserTypeDetails] = useState("");

  const { id } = useParams();

  const { socket } = useNotifications()

  useEffect(() => {
    const handleMessageReceive = (newMessage) => {
      // Update chatsList.conversation array with the new message
      setChatHistory((prevChats) => {
        const updatedConversations = prevChats.conversations.map(
          (conversation) => {
            if (conversation._id === newMessage.conversationId._id) {
              // Update the conversation with the new message details
              return {
                ...conversation,
                lastMessage: newMessage.text,
                lastMessageAt: newMessage.createdAt,
                recruiterUnreadMessages:
                  newMessage.sender !== "RECRUITER"
                    ? conversation.recruiterUnreadMessages + 1
                    : conversation.recruiterUnreadMessages,
                talentUnreadMessages:
                  newMessage.sender !== "TALENT"
                    ? conversation.talentUnreadMessages + 1
                    : conversation.talentUnreadMessages,
                adminUnreadMessages:
                  newMessage.sender !== "ADMIN"
                    ? conversation.adminUnreadMessages + 1
                    : conversation.adminUnreadMessages,
              };
            }
            return conversation;
          }
        );

        // Move the updated conversation to the top of the list
        const updatedChats = {
          ...prevChats,
          conversations: updatedConversations.sort((a, b) => {
            if (a._id === newMessage.conversationId._id) return -1;
            return 1;
          }),
        };

        return updatedChats;
      });
    };

    socket.on("receive-message", handleMessageReceive);

    // Cleanup to remove the listener when the component unmounts or socket changes
    return () => {
      socket.off("receive-message", handleMessageReceive);
    };
  }, [socket]);

  useEffect(() => {
    const fetchChatHistory = async () => {
      if (id) {
        setLoading(true);
        try {
          const determinedUserType = await determineUserType(id);
          setUserType(determinedUserType);

          let chatHistoryData;
          if (determinedUserType === "recruiter") {
            chatHistoryData = await api.getRecruitersChatHistory(id);
          } else if (determinedUserType === "talent") {
            chatHistoryData = await api.getTalentsChatHistory(id);
          } else if (determinedUserType === "admin") {
            chatHistoryData = await api.getAdminChatHistory(id);
          } else {
            throw new Error("Unknown user type");
          }
          const conversations = chatHistoryData.data?.conversations;
          conversations.forEach((conversation) => {
            let userId = service.getUserID();
            const data = {
              conversationId: conversation._id,
              userId: userId,
            };
            socket.emit("join", data, (response) => {
              console.log(
                "Join response for conversation",
                conversation._id,
                ":",
                response
              );
            });
          });

          setChatHistory(chatHistoryData.data);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      } else {
        api
          .getConversations()
          .then((res) => setChatHistory(res.data))
          .catch(console.error)
          .finally(() => setLoading(false));
      }
    };

    fetchChatHistory();
  }, [id, socket]);

  const determineUserType = async (userId) => {
    try {
      const recruiterResponse = await api.getRecuriter(userId);
      setUserTypeDetails(recruiterResponse.data.recruiter);
      if (
        recruiterResponse.data &&
        recruiterResponse.data.recruiter.recruiter_position
      ) {
        return "recruiter";
      }
    } catch (error) {
      // Handle error if needed, but continue to check for talent
    }

    try {
      const talentResponse = await api.getTalent(userId);
      setUserTypeDetails(talentResponse.data);
      if (talentResponse.data && talentResponse.data.full_time_decision) {
        return "talent";
      }
    } catch (error) {
      // Handle error if needed
    }

    try {
      const adminResponse = await api.adminById(userId);
      setUserTypeDetails(adminResponse.data);
      if (adminResponse.data) {
        return "admin";
      }
    } catch (error) {
      // Handle error if needed
    }

    return "unknown";
  };

  return (
    <div
      id="Onboarding_Talent_SectionC_Main_Component"
      ref={top}
      className="conversations-container"
    >
      <div className="conversations-content">
        <div
          className={`chat-list-container ${selectedChat ? "hide-on-mobile" : ""
            }`}
        >
          <ChatList
            chatsList={chatHistory}
            onSelectChat={setSelectedChat}
            loading={loading}
            userType={userType}
            socket={socket}
            userTypeDetails={userTypeDetails}
          />
        </div>
        <div
          className={`chat-window-container ${selectedChat ? "show-on-mobile" : ""
            }`}
        >
          <ChatWindow
            userType={userType}
            socket={socket}
            chat={selectedChat}
            userTypeDetails={userTypeDetails}
            onBack={() => setSelectedChat(null)}
          />
        </div>
      </div>
    </div>
  );
};

export default Conversations;

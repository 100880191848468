import React, { useEffect } from "react";
import { useState } from "react";
import "./css/Referral.css";
import Button from "@/component/buttons/Button";
import CollapsibleTable from "./referralTable/ReferralTable";
import CsvDownloadButton from "react-json-to-csv";
import api from "../../../../services/api";
import InputField from "../../components/inputField/InputField";
import Loading from "../../components/loading/loading";
import Pagination from "../../components/pagination/Pagination";
import UpdateReferralCoupon from "../../components/modal/Referral Coupon Update/UpdateReferralCoupon";
import { FormControlLabel, Switch, styled } from "@mui/material";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "grey", // Rest state color
    "&.Mui-checked": {
      color: "#CB5600", // Checked state color
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#CB5600", // Checked state track color
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "grey", // Rest state track color
  },
}));
const Referral = () => {
  // eslint-disable-next-line
  const [referrals, setReferrals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    startDate: "",
    endDate: "",
    talentName: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const [allCouponsClosed, setAllCouponsClosed] = useState(false);

  const handleAllCouponsClose = (event) => {
    setAllCouponsClosed(event.target.checked);
  };

  const [updatePercentage, setUpdatePercentage] = useState(false);
  const [formDetails, setFormDetails] = useState({
    jobSeeker: {
      current: "",
      new: "",
    },
    freelancer: {
      current: "",
      new: "",
    },
  });

  const handleUpdatePercentageChange = (e) => {
    const { name, value } = e.target;
    const [category, field] = name.split(".");

    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [category]: {
        ...prevDetails[category],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    const filters = {};
    if (filterOptions.startDate) {
      filters.startDate = filterOptions.startDate;
    }
    if (filterOptions.endDate) {
      filters.endDate = filterOptions.endDate;
    }
    if (filterOptions.talentName) {
      filters.talentName = filterOptions.talentName;
    }
    const fetchReferrals = () => {
      api
        .getReferrals({
          page: currentPage,
          limit: itemsPerPage,
          ...filters,
        })
        .then((res) => {
          setReferrals(res.data.data);
        })
        .catch((res) => console.log(res))
        .finally(() => setIsLoading(false));
    };

    fetchReferrals();
    // eslint-disable-next-line
  }, [currentPage, filterOptions]);

  useEffect(() => {
    api
      .getReferralsPercentage()
      .then((res) => {
        setFormDetails((prev) => ({
          ...prev,
          jobSeeker: {
            current: res.data?.talentReferralPercentage || "",
          },
          freelancer: {
            current: res.data?.freelancerReferralPercentage || "",
          },
        }));
      })
      .catch(console.error);
  }, []);

  const changePercentage = () => {
    if (formDetails.jobSeeker.new !== "" || formDetails.freelancer.new !== "") {
      api
        .setReferralsPercentage({
          talentPercentage: formDetails.jobSeeker.new,
          freelancerPercentage: formDetails.freelancer.new,
        })
        .then((res) => setUpdatePercentage(false))
        .catch(console.error);
    }
  };

  // pagination
  const totalNumberOfPages = Math.ceil(referrals?.length / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      talentName: searchTerm,
    }));
  };

  // eslint-disable-next-line no-unused-vars
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <div className="top-container">
            <h2 className="name my-2">Referral Coupons</h2>
            <div className="d-flex gap-2 align-items-center">
              <FormControlLabel
                value="Close all coupons"
                control={
                  <CustomSwitch
                    onChange={handleAllCouponsClose}
                    checked={allCouponsClosed}
                  />
                }
                label={<small>Close all coupons</small>}
                labelPlacement="start"
              />
              <Button
                icon={<i class="fa-solid fa-arrows-rotate"></i>}
                text="Update Percentage"
                primaryNull={true}
                small={true}
                onClick={() => setUpdatePercentage(!updatePercentage)}
              />
            </div>
          </div>
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between ">
                <div className="form d-lg-flex align-items-start gap-4 col-lg-10">
                  <div
                    className={`input-search ${
                      filterType === "" && "col-lg-5"
                    } col-lg-4 d-flex py-2 mt-1`}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for users"
                      className="input_text"
                      value={filterOptions.recruiterName}
                      onChange={handleSearch}
                    />
                  </div>
                  <div
                    className={`input-filter  ${
                      filterType === "" && "col-lg-5"
                    } col-lg-3 d-flex pt-3`}
                  >
                    <select
                      className="input_text"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <option selected value="">
                        Filter by date range
                      </option>
                      <option value="date">date</option>
                    </select>
                  </div>
                  {filterType !== "" && (
                    <div className="col-lg-3 d-flex align-items-end py-2 my-4 my-lg-0">
                      {filterType === "date" && (
                        <div className="row" style={{ marginTop: "-20px" }}>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ width: "80%" }}
                          >
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"Start date"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    startDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"End date"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    endDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="d-flex gap-3 my-3 my-lg-0">
                    <div className="position-relative">
                      <Button
                        text="Sort"
                        primaryNull={true}
                        small={true}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                      {isOpen && (
                        <div
                          className="position-absolute text-white p-2 rounded mt-2"
                          style={{
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            minWidth: "180px",
                            backgroundColor: "#CC5600",
                          }}
                        >
                          <Button
                            text={`By Date`}
                            primaryNull
                            small
                            onClick={""}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <CsvDownloadButton
                        data={[]}
                        filename={`joblisting-${currentPage}`}
                      >
                        <Button
                          text="Export"
                          primaryNull={false}
                          small={true}
                        />
                      </CsvDownloadButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <CollapsibleTable rows={referrals} />
                  <Pagination
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalNumberOfPages={totalNumberOfPages}
                  />
                </>
              )}
            </>
          </div>
          <UpdateReferralCoupon
            open={updatePercentage}
            details={formDetails}
            handleChange={handleUpdatePercentageChange}
            handleClose={() => setUpdatePercentage(false)}
            send={changePercentage}
          />
        </div>
      </div>
    </div>
  );
};

export default Referral;

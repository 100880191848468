import { useRef, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import api from '../../../../services/api';
import { CircularProgress } from '@mui/material';


const Advert = ({ placeholder, spaceId, slot = 'desktop', onSuccess }) => {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger the file input when the image is clicked
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const formData = new FormData();
      formData.append('picture', file);
      formData.append('width', placeholder.width || 0);
      formData.append('height', placeholder.height || 0);
      formData.append('slot', slot); // slot can be 'desktop', 'tablet', or 'mobile'
      setLoading(true);
      api.uploadImageSpace(spaceId, formData)
        .then((response) => {
          alert('Image uploaded successfully:');
          onSuccess();
        })
        .catch((error) => {
          alert('Error uploading image:');
        }).finally(() => {
          setLoading(false);
        });
    }
  };

  const deleteAdvert = () => {
    setLoading(true);
    api.deleteSpaceImage(spaceId, slot)
      .then((res) => {
        alert('Space Image deleted');
        onSuccess();
      })
      .catch((error) => {
        alert('Error deleting image');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <article className="border p-1 bg-light rounded-3" style={{ minWidth: '200px', width: '240px', maxWidth: '300px' }}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <img
              src={placeholder.url || "/img/advert.png"}
              alt="Advert"
              className="img-fluid me-3"
              style={{ width: '50px', height: '50px', objectFit: 'contain' }}
            />
          )}
          <div>
            <p className="m-0">{placeholder.width} X {placeholder.height}</p>
          </div>
        </div>

        <div className="d-flex">
          <BorderColorIcon className="me-2 text-primary" style={{ cursor: 'pointer' }} onClick={handleImageClick} />
          <DeleteOutlineIcon className="text-danger" style={{ cursor: 'pointer' }} onClick={deleteAdvert} />
        </div>
      </div>

      {/* Hidden file input for image upload */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />
    </article>
  );
};

export default Advert;

import Modal from "@mui/material/Modal";
import Modalheader from "../Modalheader";
import "../modal.css";
import Box from "@mui/material/Box";
import InputField from "../../inputField/InputField";
import Button from "@/component/buttons/Button";
import { Save } from "@mui/icons-material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 530,

  width: '100%',
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const UpdateReferralCoupon = ({ open, details, handleClose, send, handleChange }) => {

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"SET PERCENTAGE"} handleClose={handleClose} />
          <div className="modal_body p-3">
            <div>
              <h4 className="my-3">Job Seeker</h4>
              <article className="d-flex gap-3 align-items-center mt-3">
                <div>
                  <InputField
                    name={'jobSeeker.current'}
                    value={details.jobSeeker.current}
                    onChange={handleChange}
                    placeholder="0%"
                    title="Current Percentage (%)"
                    variant="outlined"
                    className={"modal_input,"}
                    style={{outline:'none'}}
                  />
                </div>
                <div>
                  <InputField
                    name={'jobSeeker.new'}
                    value={details.jobSeeker.new}
                    onChange={handleChange}
                    placeholder="Enter Percentage"
                    title="New Percentage (%)"
                    variant="outlined"
                    className={"modal_input"}
                  />
                </div>
              </article>
            </div>

            <div >
              <h4 className="my-3">Freelancer</h4>

              <article className="d-flex gap-3 align-items-center mt-3">
                <div>
                  <InputField
                    name={'freelancer.current'}
                    value={details.freelancer.current}
                    onChange={handleChange}
                    placeholder="0%"
                    title="Current Percentage (%)"
                    variant="outlined"
                    className={"modal_input"}
                  />
                </div>
                <div>
                  <InputField
                    name={'freelancer.new'}
                    value={details.freelancer.new}
                    onChange={handleChange}
                    placeholder="Enter Percentage"
                    title="New Percentage (%)"
                    variant="outlined"
                    className={"modal_input"}
                  />
                </div>
              </article>

              <div className="d-flex gap-3 align-items-center mt-3">
                <Button
                  text="Cancel"
                  primaryNull={true}
                  small={true}
                  onClick={() => handleClose()}
                />
                <Button
                  text="Save"
                  primaryNull={false}
                  small={true}
                  onClick={send}
                  icon={<Save />}
                />

              </div>
            </div>

          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default UpdateReferralCoupon;
